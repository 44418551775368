import React from "react"
import SEO from "../components/seo"
import GridItem from "../components/grid-item"
import { BodyCopy, Standfirst } from "../components/styles/textStyles"

const OurClients = () => (
  <>
    <SEO title="Our Clients" />
    <GridItem start="3" end="7">
      <Standfirst>Strategic real estate advice <br/>for every circumstance.</Standfirst>
    </GridItem>
    <GridItem start="3" end="5" tabletEnd="7">
      <BodyCopy>
        <p>Our clients include major institutions and developers such as JPMorgan, Prudential, Blackrock, Brookfield, Hines and RXR, as well as small stakeholders who value world-class capabilities and personal, focused attention.</p>
        <p>In New York City, our experience includes consultation on notable properties such as Waldorf Astoria New York, Hudson Yards, 432 Park Avenue, Rockefeller Center, 7 World Trade Center, Four Seasons Hotel and Residences Downtown, and the South Street Seaport.</p>
      </BodyCopy>
    </GridItem>
    <GridItem start="5" end="7" tabletStart="3" tabletEnd="7" insetContent="10px">
      <BodyCopy>
        <p>Examples elsewhere include The Ritz-Carlton Rancho Mirage in Palm Springs, the Palmolive Building in Chicago, Kansas City Power & Light District, the Dallas Galleria and Museum Tower in Dallas, The Setai Miami Beach, the Royal Sonesta in New Orleans, One City Centre in Houston, The Ritz-Carlton Chicago, Oakland’s Jack London Square, Water Tower Place in Chicago, Philadelphia’s 10 Rittenhouse Square, Playa Vista and The Bloc in Los Angeles, Boston's Landmark Center, Central Park East in Phoenix and the Mandarin Oriental in Washington D.C.</p>
        <p>Weitzman works in markets big and small, anywhere in the world.</p>
      </BodyCopy>
    </GridItem>
  </>
)

export default OurClients
